@import "./assets/scss/_config.scss";
@import 'reviews_recent22';

#content-reviews .recent-reviews__info {
  padding-inline: 0;
}

.reviews-container {
  width: inherit;
  margin-right: 0;
}

@media (min-width: 62rem) {
  .reviews-container {
    width: calc(75% - 15px);
    margin-right: 15px;
  }
}

.reviews .review {
  position: relative;
  margin-bottom: 1.5rem;
  padding: 10px 0;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  font-size: .875rem;
}

.reviews .review .date {
  position: absolute;
  left: 15px;
  top: 55px;
  color: var(--nav-color);
  font-size: .875rem;
}

@media all and (min-width: 768px) {
  .reviews .review .date {
    top: auto;
    bottom: 15px;
  }
}

.reviews .review .review-title {
  display: block;
  margin-bottom: 10px;
  padding-block: 1rem 0;
  color: var(--primary);
  font-weight: 600;
  font-size: 1.125rem;
}

.reviews .review .pros {
  margin-top: 40px;
}

@media all and (min-width: 768px) {
  .reviews .review .pros {
    margin-top: 0;
  }

  .reviews .review .pros,
  .reviews .review .cons {
    margin-bottom: 20px;
    margin-right: 75px;
  }
}

.reviews .review .pros,
.reviews .review .cons {
  margin-bottom: 20px;
}

.reviews .review .score {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 36px;
  padding: 5px 0;
  color: var(--primary);
  font-weight: 600;
  font-size: 1.75rem;
}
